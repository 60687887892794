/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-28 16:27:09
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-05-11 17:09:57
 */
import axios from 'axios'
import {Message} from 'element-ui'

let apiServer = "https://api.ukoocloud.com";

function request(method, url, data, callback) {
  let token = localStorage.getItem("token");
  var authorization = 'Bearer ' + token
  if (url.indexOf("http") == -1) {
    url = apiServer + url;
  }
  axios({
      method: method,
      url: url,
      data: method.toLowerCase() === 'post' ? data : null, 
      params: method.toLowerCase() === 'get' ? data : null, 
      withCredentials: false,
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/json',//'multipart/form-data',
        'Authorization': authorization,
        'Platform': 2
      }
  }).then(function(res){
      if(res.data.code === 200){
        callback && callback(res.data);
      }else{
        Message(res.data.msg);
        if (res.data.code == 401) {
          localStorage.clear()
          location.href="/";
          return
        }
      }
  }).catch(function(err){
    console.log(err);
  });
}
export default {
  get(url, data, callback) {
      return request('get', url, data, callback);
  },
  post(url, data, callback) {
      return request('post', url, data, callback);
  }
}