<template>
    <div class="selectPackage">
        <div class="sp-title flex-wc">
            <div class="balck" @click="clickGoBalck">
                <i class="el-icon-arrow-left"></i>
            </div>
            <div class="title">选择套餐</div>
        </div>
        <div class="open-wrap" style="height: 550px">
            <div class="ow-content">
                <div class="ow-c-item" style="padding-top: 20px">
                    <div class="ci-tit flex-wc">
                        <div class="title">套餐选择</div>
                    </div>
                    <div class="ci-box flex-sbc">
                        <div class="item flex-cc" :class="currentIndex == index?'active':''"
                            v-for="(item,index) in renewList" :key="index"
                            @click="clickRenew(item,index)">
                            {{item.name}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="price-wrap flex-sbc">
                <div class="pw-left flex-sbc">
                    <div class="item">
                        <div class="tip">订阅年费</div>
                        <div class="price">￥{{renewPrice}}/年</div>
                    </div>
                </div>
                <el-button color="#0068ff" size="large" type="primary" @click="getRegister">去支付</el-button>
            </div>
        </div>
         <!-- 扫码支付 -->
        <el-dialog
            :visible.sync="isPriceCodeShow"
            width="200"
            :show-close="false">
            <div class="price-code flex-coc">
                <img width="140" height="140" class="img" :src="payImg" alt="">
                <div class="tip">微信扫码支付</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/utils/common.js';
export default {
    data () {
        return {
            renewList: [],
            renewPrice: '',
            currentIndex: 0,
            activate_package_id: '',
            info: {},
            order_number: '',
            payImg: '',
            isPriceCodeShow: false,
            timer: null,
            counter: 0
        }
    },
    mounted () {
        this.info = JSON.parse(this.$route.query.data);
        console.log(this.info);
        this.getActivatePackage()
    },
    methods: {
        // 获取支付状态
        getPayStatus(){
            let params = {
                order_number: this.order_number
            }
            api.post('/api/login/pay_status',params, (res) => {
                if(res.data.status === 1){
                    this.$message.success('支付成功')
                    this.openState = 1
                    this.isPriceCodeShow = false
                    this.isCodeShow = false
                    clearInterval(this.timer); 
                    setTimeout(() => {
                        this.$router.go(-2) 
                        window.location.href = 'weixin://dl/business/?appid=wx44d4edf1b57daea8';
                    }, 1000);
                }else{
                    this.counter++; 
                    if (this.counter >= 30) { 
                        clearInterval(this.timer); 
                        ElMessage({message: '支付失败',type: 'warning'});
                    }
                }
            });
        },
        // 去支付
        getRegister(){
            let params = {
                openid: '',
                unionid: '',
                company_name: this.info.name,
                phone: this.info.phone,
                code: this.info.code,
                invitation_code: this.info.invitation_code,
                province: this.info.province,
                city: this.info.city,
                district: this.info.area,
                activate_package_id: this.activate_package_id
            };
            api.post('/api/login/register',params, (res) => {
                this.payImg = res.data.qr_code
                this.order_number = res.data.order_number
                this.isPriceCodeShow = true
                this.timer = setInterval(() => {
                    this.getPayStatus();
                }, 2000);
            });
        },
        // 获取套餐
        getActivatePackage(){
            api.get('/api/login/get_activate_package_whole', {}, (res) => {
                this.renewList = res.data.list;
                this.activate_package_id = this.renewList[0].id;
                this.renewPrice = this.renewList[0].price;
            });
        },
        clickRenew(item,index){
            this.currentIndex = index;
            this.activate_package_id = item.id;
            this.renewPrice = item.price;
        },
        clickGoBalck(){
            this.$router.go(-1) 
        },
    },
}
</script>

<style lang='scss'>
.selectPackage{
    .sp-title{
        height: 50px;
        padding: 0 20px;
        position: relative;
        .balck{
            width: 50px;
            .el-icon-arrow-left{
                font-size: 18px;
                color: #101010;
                font-weight: bold;
            }
        }
        .title{
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 16px;
            color: #101010;
            font-weight: bold;
        }
    }
    .open-wrap{
        .ow-content{
            padding: 30px 20px;
            .ow-c-item{
                .ci-tit{
                    font-size: 18px;
                    font-weight: bold;
                    color: #101010;
                    .tag{
                        color: #ff0000;
                    }
                }
                .ci-box{
                    padding: 15px 0;
                    width: 100%;
                    flex-wrap: wrap;
                    .item{
                        background: #eff5ff;
                        border-radius: 6px;
                        font-size: 14px;
                        color: #000;
                        cursor: pointer;
                        height: 70px;
                        width: calc((100% - 30px)/2);
                        margin-bottom: 20px;
                    }
                    .active{
                        background: #0068ff;
                        color: #fff;
                    }
                }
             
            }
        }
        .price-wrap{
            background: #f5f5f5;
            padding: 20px 40px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            .pw-left{
                .item{
                    .price{
                        font-size: 20px;
                        color: #101010;
                        font-weight: bold;
                        padding-top: 5px;
                    }
                    .tag{
                        font-size: 12px;
                        padding-top: 10px;
                    }
                }
            }
        }
    }
    .price-code{
        padding: 20px;
        .tip{
            font-size: 14px;
            padding-top: 10px;
        }
    }
}
</style>
